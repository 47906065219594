// import React from 'react';
// import { Link } from 'react-router-dom';

// const Navbar = () => {
//   return (
//     <nav className="navbar navbar-light bg-light navbar-expand-lg ml-auto">
//       <div className="container-fluid">
//         <button
//           className="btn btn-dark d-inline-block d-lg-none ml-auto"
//           type="button"
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//           aria-controls="navbarSupportedContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <i className="fas fa-align-justify"></i>
//         </button>
//         <div className="collapse navbar-collapse" id="navbarSupportedContent">
//           <ul className="navbar-nav ml-auto">
//             <li>
//               <Link to="/" className="nav-link">
//                 Log In
//               </Link>
//             </li>
//             <li>
//               <Link to="/" className="nav-link">
//                 Sign Out
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;  



// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
// import { useAuth } from '../components/context/auth.context';

// const Navbar = () => {
//   const { isAuthenticated, logout } = useAuth();
//   const navigate = useNavigate(); // Initialize useNavigate hook

//   const handleLogout = () => {
//     // Perform logout action
//     logout();
//     // Navigate to the login page after logout
//     navigate('/login');
//   };

//   return (
//     <nav className="navbar navbar-light bg-light navbar-expand-lg ml-auto">
//       <div className="container-fluid">
//         <button
//           className="btn btn-dark d-inline-block d-lg-none ml-auto"
//           type="button"
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//           aria-controls="navbarSupportedContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <i className="fas fa-align-justify"></i>
//         </button>
//         <div className="collapse navbar-collapse" id="navbarSupportedContent">
//           <ul className="navbar-nav ml-auto">
//             {isAuthenticated ? (
//               <li>
//                 <button onClick={handleLogout} className="nav-link btn btn-link" style={{ cursor: 'pointer' }}>
//                   Log Out
//                 </button>
//               </li>
//             ) : (
//               <li>
//                 <Link to="/login" className="nav-link">
//                   Log Out
//                 </Link>
//               </li>
//             )}
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;




import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../components/context/auth.context';

const Navbar = () => {
  const { isAuthenticated, authData, logout } = useAuth(); // Use useAuth hook
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <nav className="navbar navbar-light bg-light navbar-expand-lg ml-auto">
      <div className="container-fluid">
        <span className="navbar-brand">Welcome, {authData.email}</span> 
        <button
          className="btn btn-dark d-inline-block d-lg-none ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-align-justify"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {isAuthenticated ? (
              <li>
                <button onClick={handleLogout} className="nav-link btn btn-link" style={{ cursor: 'pointer' }}>
                  Log Out
                </button>
              </li>
            ) : (
              <li>
                {/* <Link to="/login" className="nav-link">
                  Log in
                </Link> */}
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
