import React, { useState, useEffect } from 'react';
import axios from 'axios';

import CreateProject from "./create-project.component";
const Project = ({ project, deleteProject }) => (
    <tr>
        <td>{project.name}</td>
        <td>
            <a href="#" onClick={() => { 
                if(window.confirm('Are you sure you want to delete this project?')) 
                    deleteProject(project._id) 
            }} 
            className="badge badge-danger">Delete</a>
        </td>
    </tr>
);

const ManageProjects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        axios.get('http://support.systoolsacademy.com/projects/')
        // axios.get('http://localhost:5000/projects/')
            .then(res => {
                setProjects(res.data);
            })
            .catch(error => console.log(error));
    }, []);

    const deleteProject = (id) => {
        axios.delete('http://support.systoolsacademy.com/projects/' + id)
        // axios.delete('http://localhost:5000/projects/' + id)
            .then(res => { console.log(res.data) });

        // update projects array to all projects without matching id
        setProjects(projects.filter(el => el._id !== id));
    }

    const getProjects = () => {
        return projects.map(currentProject => {
            return <Project
                project={currentProject}
                deleteProject={deleteProject}
                key={currentProject._id}
            />;
        })
    }

    return (
        <div>
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {getProjects()}
                </tbody>
            </table>
            <br></br>
            <CreateProject />
        </div>
    );
}

export default ManageProjects;
