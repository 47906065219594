// import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
// import logo from '../assets/logo.png';

// export default class Sidebar extends Component {
// 	render() {
// 		return(
// 			<nav class="col-md-2 d-none d-md-block bg-light sidebar">
// 	  			<center><img src={logo} className="navbar-brand" width="120" alt="Tech support" /></center>
// 	    		<ul class="nav flex-column">
// 	    			<li className="nav-item">
// 	    				<NavLink to="/" onlyActiveOnIndex={true} className="nav-link" activeClassName="active">
// 	    					<i class="fas fa-home"></i>
// 	    					Dashboard Home
// 	    				</NavLink>
// 	    			</li>
// 	    			<li>
//                 		<NavLink to="/tickets/create" className="nav-link" activeClassName="active">
//                 			<i class="fas fa-ticket-alt"></i>
//                 			Submit a Ticket
//                 		</NavLink>
//             		</li>
//             		<li>
//                 		<NavLink to="/manage-users" className="nav-link" activeClassName="active">
//                 			<i class="fas fa-users"></i>
//                 			Manage Users
//                 		</NavLink>
//             		</li>
//             		<li>
//                 		<NavLink to="/manage-projects" className="nav-link" activeClassName="active">
//                 			<i class="fas fa-folder"></i>
//                 			Manage Projects
//                 		</NavLink>
//             		</li>
// 	    		</ul>
// 			</nav>
// 		);
// 	}
// }
// Sidebar.js

// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import logo from '../assets/logo.png';

// const Sidebar = ({ isAuthenticated }) => {
//   return (
//     <nav className="col-md-2 d-none d-md-block bg-light sidebar">
//       <center><img src={logo} className="navbar-brand" width="120" alt="Tech support" /></center>
//       {isAuthenticated && (
//         <ul className="nav flex-column">
//           <li className="nav-item">
//             <NavLink to="/" onlyActiveOnIndex={true} className="nav-link" activeClassName="active">
//               <i className="fas fa-home"></i>
//               Dashboard Home
//             </NavLink>
//           </li>
//           <li>
//             <NavLink to="/tickets/create" className="nav-link" activeClassName="active">
//               <i className="fas fa-ticket-alt"></i>
//               Submit a Ticket
//             </NavLink>
//           </li>
//           <li>
//             <NavLink to="/manage-users" className="nav-link" activeClassName="active">
//               <i className="fas fa-users"></i>
//               Manage Users
//             </NavLink>
//           </li>
//           <li>
//             <NavLink to="/manage-projects" className="nav-link" activeClassName="active">
//               <i className="fas fa-folder"></i>
//               Manage Projects
//             </NavLink>
//           </li>
//         </ul>
//       )}
//     </nav>
//   );
// }

// export default Sidebar;


// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import logo from '../assets/logo.png';

// const Sidebar = ({ isAuthenticated, userRole }) => {
//   return (
//     <nav className="col-md-2 d-none d-md-block bg-light sidebar">
//       <center><img src={logo} className="navbar-brand" width="120" alt="Tech support" /></center>
//       {isAuthenticated && (
//         <ul className="nav flex-column">
//           {userRole === 'developer' && (
//             <li className="nav-item">
//               <NavLink to="/" exact className="nav-link" activeClassName="active">
//                 <i className="fas fa-home"></i>
//                 Dashboard Home
//               </NavLink>
//             </li>
//           )}
//           {userRole === 'support' && (
//             <>
//               <li className="nav-item">
//                 <NavLink to="/" exact className="nav-link" activeClassName="active">
//                   <i className="fas fa-home"></i>
//                   Dashboard Home
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink to="/tickets/create" className="nav-link" activeClassName="active">
//                   <i className="fas fa-ticket-alt"></i>
//                   Submit a Ticket
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink to="/manage-users" className="nav-link" activeClassName="active">
//                   <i className="fas fa-users"></i>
//                   Manage Users
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink to="/manage-projects" className="nav-link" activeClassName="active">
//                   <i className="fas fa-folder"></i>
//                   Manage Projects
//                 </NavLink>
//               </li>
//             </>
//           )}
//           {userRole === 'admin' && (
//             <>
//               <li className="nav-item">
//                 <NavLink to="/" exact className="nav-link" activeClassName="active">
//                   <i className="fas fa-home"></i>
//                   Dashboard Home
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink to="/tickets/create" className="nav-link" activeClassName="active">
//                   <i className="fas fa-ticket-alt"></i>
//                   Submit a Ticket
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink to="/manage-users" className="nav-link" activeClassName="active">
//                   <i className="fas fa-users"></i>
//                   Manage Users
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink to="/manage-projects" className="nav-link" activeClassName="active">
//                   <i className="fas fa-folder"></i>
//                   Manage Projects
//                 </NavLink>
//               </li>
//             </>
//           )}
//         </ul>
//       )}
//     </nav>
//   );
// }

// export default Sidebar;


// sidebar.js

// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { useAuth } from '../components/context/auth.context';

// const Sidebar = () => {
//   const { authData } = useAuth();
//   const userRole = authData ? authData.role : null;

//   console.log('User role:', userRole);

//   return (
//     <nav>
//       <ul>
//         {userRole === 'developer' && (
//           <li>
//             <NavLink to="/" exact>Dashboard Home</NavLink>
//           </li>
//         )}
//         {userRole === 'support' && (
//           <>
//             <li><NavLink to="/" exact>Dashboard Home</NavLink></li>
//             <li><NavLink to="/tickets/create">Submit a Ticket</NavLink></li>
//             <li><NavLink to="/manage-users">Manage Users</NavLink></li>
//             <li><NavLink to="/manage-projects">Manage Projects</NavLink></li>
//           </>
//         )}
//         {userRole === 'admin' && (
//           <>
//             <li><NavLink to="/" exact>Dashboard Home</NavLink></li>
//             <li><NavLink to="/tickets/create">Submit a Ticket</NavLink></li>
//             <li><NavLink to="/manage-users">Manage Users</NavLink></li>
//             <li><NavLink to="/manage-projects">Manage Projects</NavLink></li>
//           </>
//         )}
//       </ul>
//     </nav>
//   );
// };

// export default Sidebar;



// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { useAuth } from '../components/context/auth.context';
// import logo from '../assets/logo.png';

// const Sidebar = () => {
//   const { authData } = useAuth();
//   const userRole = authData ? authData.role : null;

//   console.log('User role:', userRole);

//   return (
//     <nav className="col-md-2 d-none d-md-block bg-light sidebar">
//       <center><img src={logo} className="navbar-brand" width="120" alt="Tech support" /></center>
      
//         <ul className="nav flex-column">
//           {userRole === 'developer' && (
//             <li className="nav-item">
//               <NavLink to="/" exact className="nav-link" activeClassName="active">
//                 <i className="fas fa-home"></i>
//                 Dashboard Home
//               </NavLink>
//             </li>
//           )}
//           {userRole === 'support' && (
//             <>
//               <li className="nav-item">
//                 <NavLink to="/" exact className="nav-link" activeClassName="active">
//                   <i className="fas fa-home"></i>
//                   Dashboard Home
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink to="/tickets/create" className="nav-link" activeClassName="active">
//                   <i className="fas fa-ticket-alt"></i>
//                   Submit a Ticket
//                 </NavLink>
//               </li>
              
//               <li className="nav-item">
//                 <NavLink to="/manage-projects" className="nav-link" activeClassName="active">
//                   <i className="fas fa-folder"></i>
//                   Manage Projects
//                 </NavLink>
//               </li>
//             </>
//           )}
//           {userRole === 'admin' && (
//             <>
//               <li className="nav-item">
//                 <NavLink to="/" exact className="nav-link" activeClassName="active">
//                   <i className="fas fa-home"></i>
//                   Dashboard Home
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink to="/tickets/create" className="nav-link" activeClassName="active">
//                   <i className="fas fa-ticket-alt"></i>
//                   Submit a Ticket
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink to="/manage-users" className="nav-link" activeClassName="active">
//                   <i className="fas fa-users"></i>
//                   Manage Users
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink to="/manage-projects" className="nav-link" activeClassName="active">
//                   <i className="fas fa-folder"></i>
//                   Manage Projects
//                 </NavLink>
//               </li>
//             </>
//           )}
//         </ul>
    
//     </nav>
//   );
// };

// export default Sidebar;



import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../components/context/auth.context';
import logo from '../assets/logo.png';

const Sidebar = () => {
  const { authData, isAuthenticated } = useAuth();
  const userRole = authData ? authData.role : null;

  if (!isAuthenticated) {
    return null;
  }

  return (
    <nav className="col-md-2 d-none d-md-block bg-light sidebar">
      <center><img src={logo} className="navbar-brand" width="120" alt="Tech support" /></center>
      <ul className="nav flex-column">
        {userRole === 'developer' && (
          <li className="nav-item">
            <NavLink to="/" exact className="nav-link" activeClassName="active">
              <i className="fas fa-home"></i>
              Dashboard Home
            </NavLink>
          </li>
        )}
        {userRole === 'support' && (
          <>
            <li className="nav-item">
              <NavLink to="/" exact className="nav-link" activeClassName="active">
                <i className="fas fa-home"></i>
                Dashboard Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/tickets/create" className="nav-link" activeClassName="active">
                <i className="fas fa-ticket-alt"></i>
                Submit a Ticket
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/manage-projects" className="nav-link" activeClassName="active">
                <i className="fas fa-folder"></i>
                Manage Projects
              </NavLink>
            </li>
          </>
        )}
        {userRole === 'admin' && (
          <>
            <li className="nav-item">
              <NavLink to="/" exact className="nav-link" activeClassName="active">
                <i className="fas fa-home"></i>
                Dashboard Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/tickets/create" className="nav-link" activeClassName="active">
                <i className="fas fa-ticket-alt"></i>
                Submit a Ticket
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/manage-users" className="nav-link" activeClassName="active">
                <i className="fas fa-users"></i>
                Manage Users
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/manage-projects" className="nav-link" activeClassName="active">
                <i className="fas fa-folder"></i>
                Manage Projects
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Sidebar;
