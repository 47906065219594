// import React, { Component } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import MarkButton from './mark-button';

// let getPriorities = (lvl) => {

//     switch(lvl) {
//         case 'Low': 
//             return <td className="low-priority">{lvl}</td>;
//         case 'Medium':
//             return <td className="med-priority">{lvl}</td>;
//         case 'High': 
//             return <td className="high-priority">{lvl}</td>;
//         default:
//             return <td>{lvl}</td>;
//     }
// }

// export default class Ticket extends Component {
    
//     constructor(props) {
//         super(props);

//         this.state = {
//             status: ''
//         }
//     }

//     componentDidMount() {
//         // default state of ticket
//         axios.get('http://localhost:5000/tickets/'+this.props.ticket._id)
//             .then(res => {
                
//                 this.setState({
//                     title: res.data.title,
//                     description: res.data.description,
//                     projectName: res.data.projectName,
//                     assignee: res.data.assignee,
//                     assigneedBy:res.data.assigneedBy,
//                     priority: res.data.priority,
//                     status: res.data.status,
//                     type: res.data.type,
//                 })
//             })
//             .catch(error => console.log(error));
//     }

//     onChangeStatus(e) {
//         // axios.post('http://localhost:5000/tickets/update/' + this.props.ticket._id, this.props.ticket)
//         //     .then(res => console.log(res.data));
//     }

//     render() {
//         return (
//             <tr>
//                 <td>{this.props.ticket.title}</td>
//                 <td>{this.props.ticket.description}</td>
//                 <td>{this.props.ticket.projectName}</td>
//                 <td>{this.props.ticket.assignee}</td>
//                 <td>{this.props.ticket.assigneedBy}</td>
//                 { getPriorities(this.props.ticket.priority) }
//                 <td>{this.props.ticket.status}</td>
//                 <td>{this.props.ticket.type}</td>
//                 <td>
//                     {/* <Link to={"/edit/"+this.props.ticket._id} className="badge badge-info">Edit</Link>
//                     <br></br>
//                     <a href="#" onClick={() => { 
//                         if(window.confirm('Are you sure you want to delete this ticket?')) 
//                             this.props.deleteTicket(this.props.ticket._id) 
//                     }} 
//                     className="badge badge-danger">Delete</a>
//                     <br></br>
                    
//                     <MarkButton 
//                         mark={this.props.ticket.status} 
//                         ticketID={this.props.ticket._id}
//                     /> */}
//                     {   /* *****
//                         *  FIX THIS TO UPDATE STATE
//                         * *****/
//                         // this.props.ticket.status !== 'Resolved' ? 
//                         // <a href="#" onClick={() => {
//                         //     this.props.ticket.status = 'Resolved' 
//                         // }} 
//                         // className="badge badge-success">Mark as Resolved</a> :
//                         // <a href="#" onClick={() => {
//                         //     this.props.ticket.status = 'Open' 
//                         // }} 
//                         // className="badge badge-secondary">Mark as Open</a>
//                     }
//                 </td>
//             </tr>
//         );
//     }
// }




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import MarkButton from './mark-button';

// const getPriorities = (lvl) => {
//     switch (lvl) {
//         case 'Low':
//             return <td className="low-priority">{lvl}</td>;
//         case 'Medium':
//             return <td className="med-priority">{lvl}</td>;
//         case 'High':
//             return <td className="high-priority">{lvl}</td>;
//         default:
//             return <td>{lvl}</td>;
//     }
// };

// const Ticket = ({ ticket, deleteTicket }) => {
//     const [ticketData, setTicketData] = useState({
//         title: '',
//         description: '',
//         projectName: '',
//         assignee: '',
//         priority: '',
//         status: '',
//         type: ''
//     });

//     useEffect(() => {
//         // Fetch ticket data
//         axios.get(`http://localhost:5000/tickets/${ticket._id}`)
//             .then(res => {
//                 const data = res.data;
//                 setTicketData({
//                     title: data.title || '',
//                     description: data.description || '',
//                     projectName: data.projectName || '',
//                     assignee: data.assignee || '',
//                     priority: data.priority || '',
//                     status: data.status || '',
//                     type: data.type || ''
//                 });
//             })
//             .catch(error => console.error(error));
//     }, [ticket._id]);

//     const handleDelete = () => {
//         if (window.confirm('Are you sure you want to delete this ticket?')) {
//             deleteTicket(ticket._id);
//         }
//     };

//     return (
//         <tr>
//             <td>{ticketData.title}</td>
//             <td>{ticketData.description}</td>
//             <td>{ticketData.projectName}</td>
//             <td>{ticketData.assignee}</td>
//             {getPriorities(ticketData.priority)}
//             <td>{ticketData.status}</td>
//             <td>{ticketData.type}</td>
//             <td>
//                 <Link to={`/edit/${ticket._id}`} className="badge badge-info">Edit</Link>
//                 <br />
//                 <button onClick={handleDelete} className="badge badge-danger">Delete</button>
//                 <br />
//                 <MarkButton mark={ticketData.status} ticketID={ticket._id} />
//             </td>
//         </tr>
//     );
// };

// export default Ticket;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import MarkButton from './mark-button';
// import { useAuth } from '../components/context/auth.context';

// const getPriorities = (lvl) => {
//     switch (lvl) {
//         case 'Low':
//             return <td className="low-priority">{lvl}</td>;
//         case 'Medium':
//             return <td className="med-priority">{lvl}</td>;
//         case 'High':
//             return <td className="high-priority">{lvl}</td>;
//         default:
//             return <td>{lvl}</td>;
//     }
// };

// const Ticket = ({ ticket, deleteTicket }) => {
//     const { authData } = useAuth(); // Fetch authData using useAuth hook
//     const userRole = authData ? authData.role : null; // Get user role from authData

//     const [ticketData, setTicketData] = useState({
//         title: '',
//         description: '',
//         projectName: '',
//         assignee: '',
//         priority: '',
//         status: '',
//         type: ''
//     });

//     useEffect(() => {
//         // Fetch ticket data
//         axios.get(`http://localhost:5000/tickets/${ticket._id}`)
//             .then(res => {
//                 setTicketData({
//                     title: res.data.title,
//                     description: res.data.description,
//                     projectName: res.data.projectName,
//                     assignee: res.data.assignee,
//                     priority: res.data.priority,
//                     status: res.data.status,
//                     type: res.data.type
//                 });
//             })
//             .catch(error => console.error(error));
//     }, [ticket._id]);

//     const handleDelete = () => {
//         if (window.confirm('Are you sure you want to delete this ticket?')) {
//             deleteTicket(ticket._id);
//         }
//     };

//     return (
//         <tr>
//             <td>{ticketData.title}</td>
//             <td>{ticketData.description}</td>
//             <td>{ticketData.projectName}</td>
//             <td>{ticketData.assignee}</td>
//             {getPriorities(ticketData.priority)}
//             <td>{ticketData.status}</td>
//             <td>{ticketData.type}</td>
//             <td>
//                 {userRole === 'developer' && (
//                     <p>This message is displayed only for developer login</p>
//                 )}
//                 {userRole !== 'developer' && ( // Only render actions if user is not a developer
//                     <>
//                         <Link to={`/edit/${ticket._id}`} className="badge badge-info">Edit</Link>
//                         <br />
//                         <button onClick={handleDelete} className="badge badge-danger">Delete</button>
//                         <br />
//                         <MarkButton mark={ticketData.status} ticketID={ticket._id} />
//                         {ticketData.status !== 'Resolved' && (
//                             <a href="#" onClick={() => {
//                                 // Handle logic to update ticket status to 'Resolved'
//                             }} className="badge badge-success">Mark as Resolved</a>
//                         )}
//                     </>
//                 )}
//             </td>
//         </tr>
//     );
// };

// export default Ticket;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import MarkButton from './mark-button';
// import { useAuth } from '../components/context/auth.context';

// const getPriorities = (lvl) => {
//     switch (lvl) {
//         case 'Low':
//             return <td className="low-priority">{lvl}</td>;
//         case 'Medium':
//             return <td className="med-priority">{lvl}</td>;
//         case 'High':
//             return <td className="high-priority">{lvl}</td>;
//         default:
//             return <td>{lvl}</td>;
//     }
// }

// const Ticket = ({ ticket, deleteTicket }) => {
//     const { authData } = useAuth();
//     const userRole = authData ? authData.role : null;

//     const [ticketData, setTicketData] = useState(null);
// console.log("ticketData :" , ticket)
//     useEffect(() => {
//         axios.get(`http://localhost:5000/tickets/${ticket._id}`)
//             .then(res => {
//                 setTicketData(res.data);
//             })
//             .catch(error => console.error("Error fetching ticket data:", error));
//     }, [ticket._id]);

//     const handleDelete = () => {
//         if (window.confirm('Are you sure you want to delete this ticket?')) {
//             deleteTicket(ticket._id);
//         }
//     };

//     if (!ticketData) {
//         return null; 
//     }

//     return (
//         <tr>
//             <td>{ticketData.title}</td>
//             <td>{ticketData.description}</td>
//             <td>{ticketData.projectName}</td>
//             <td>{ticketData.assignee}</td>
//             {getPriorities(ticketData.priority)}
//             <td>{ticketData.status}</td>
//             <td>{ticketData.type}</td>
//             {userRole !== 'developer' && (
//                 <td>
//                     <Link to={`/edit/${ticket._id}`} className="badge badge-info">Edit</Link>
//                     <br />
//                     <button onClick={handleDelete} className="badge badge-danger">Delete</button>
//                     <br />
//                     <MarkButton mark={ticketData.status} ticketID={ticket._id} />
//                     {/* Add buttons for marking as resolved or open here */}
//                 </td>
//             )}
//         </tr>
//     );
// }

// export default Ticket;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import MarkButton from './mark-button';
// import { useAuth } from '../components/context/auth.context';

// const getPriorities = (lvl) => {
//     switch (lvl) {
//         case 'Low':
//             return <td className="low-priority">{lvl}</td>;
//         case 'Medium':
//             return <td className="med-priority">{lvl}</td>;
//         case 'High':
//             return <td className="high-priority">{lvl}</td>;
//         default:
//             return <td>{lvl}</td>;
//     }
// }

// const Ticket = ({ ticket, deleteTicket }) => {
//     const { authData } = useAuth();
//     const userRole = authData ? authData.role : null;

//     const handleDelete = () => {
//         if (window.confirm('Are you sure you want to delete this ticket?')) {
//             deleteTicket(ticket.id); // Assuming ticket id is stored in the 'id' field
//         }
//     };

//     return (
//         <tr>
//             <td>{ticket.title}</td>
//             <td>{ticket.description}</td>
//             <td>{ticket.projectName}</td>
//             <td>{ticket.assignee}</td>
//             <td>{ticket.assignedBy}</td>
//             {getPriorities(ticket.priority)}
//             <td>{ticket.status}</td>
//             <td>{ticket.type}</td>
//             {userRole !== 'developer' && (
//                 <td>
//                     <Link to={`/edit/${ticket.id}`} className="badge badge-info">Edit</Link>
//                     <br />
//                     <button onClick={handleDelete} className="badge badge-danger">Delete</button>
//                     <br />
//                     <MarkButton mark={ticket.status} ticketID={ticket.id} />
//                     {/* Add buttons for marking as resolved or open here */}
//                 </td>
//             )}
//         </tr>
//     );
// }

// export default Ticket;



import React from 'react';
import { Link } from 'react-router-dom';
import MarkButton from './mark-button';
import { useAuth } from '../components/context/auth.context';

const getPriorities = (lvl) => {
    switch (lvl) {
        case 'Low':
            return <td className="low-priority">{lvl}</td>;
        case 'Medium':
            return <td className="med-priority">{lvl}</td>;
        case 'High':
            return <td className="high-priority">{lvl}</td>;
        default:
            return <td>{lvl}</td>;
    }
}

const Ticket = ({ ticket, deleteTicket, updateTicketStatus }) => {
    const { authData } = useAuth();
    const userRole = authData ? authData.role : null;

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this ticket?')) {
            deleteTicket(ticket._id); // Ensure the ticket ID is correct
        }
    };

    const handleStatusChange = (newStatus) => {
        if (window.confirm(`Are you sure you want to mark this ticket as ${newStatus}?`)) {
            updateTicketStatus(ticket._id, newStatus);
        }
    };

    return (
        <tr>
            <td>{ticket.title}</td>
            <td>{ticket.description}</td>
            <td>{ticket.projectName}</td>
            <td>{ticket.assignee}</td>
            <td>{ticket.assignedBy}</td>
            {getPriorities(ticket.priority)}
            <td>{ticket.status}</td>
            <td>{ticket.type}</td>
            {userRole !== 'developer' && (
                <td>
                    <Link to={`/edit/${ticket._id}`} className="badge badge-info">Edit</Link>
                    <br />
                    <button onClick={handleDelete} className="badge badge-danger">Delete</button>
                    <br />
                    <MarkButton 
                        mark={ticket.status} 
                        ticketID={ticket._id} 
                        onStatusChange={handleStatusChange}
                    />
                </td>
            )}
        </tr>
    );
}

export default Ticket;
