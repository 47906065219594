// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { useAuth } from '../context/auth.context.js';
// import './login.css';
// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const { login } = useAuth();
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:5000/auth/login', { email, password });
//       login(response.data.token);
//       onLogin(response.data.token);
//       navigate('/');
//     } catch (error) {
//       if (error.response) {
//         // The request was made and the server responded with a status code
//         console.error('Login error', error.response.data);
//       } else if (error.request) {
//         // The request was made but no response was received
//         console.error('No response received from server');
//       } else {
//         // Something happened in setting up the request that triggered an Error
//         console.error('Error while setting up the request:', error.message);
//       }
//     }
    
//   };

//   return (
//     <div className="login-box ">
//       <div className="card">
//         <div className="card-body login-card-body">
//           <div className="login-logo pb-3">
//             {/* <a href="#"><img src="assets/img/logo-systools.png" width="170" height="60" alt="Logo" /></a> */}
//           </div>
//           <p className="h5 font-weight-bold text-center text-navy">Welcome To Team</p>
//           <p className="login-box-msg">Sign in to start your session</p>
//           <form onSubmit={handleSubmit}>
//             <div className="input-group mb-3">
//               <input
//                 type="email"
//                 className="form-control"
//                 placeholder="Email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//               />
//               <div className="input-group-append">
//                 <div className="input-group-text">
//                   <span className="fas fa-envelope"></span>
//                 </div>
//               </div>
//             </div>
//             <div className="input-group mb-3">
//               <input
//                 type="password"
//                 className="form-control"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 required
//               />
//               <div className="input-group-append">
//                 <div className="input-group-text">
//                   <span className="fas fa-lock"></span>
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-4">
//                 <button type="submit" className="btn btn-navy btn-block">Sign In</button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { useAuth } from '../context/auth.context.js';
// import './login.css';

// const Login = ({ onLogin }) => { // Accept onLogin as a prop
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const { login } = useAuth();
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:5000/auth/login', { email, password });
//       login(response.data.token);
//       onLogin(response.data.token); // Call the onLogin function passed as prop
//       navigate('/');
//     } catch (error) {
//       if (error.response) {
//         // The request was made and the server responded with a status code
//         console.error('Login error', error.response.data);
//       } else if (error.request) {
//         // The request was made but no response was received
//         console.error('No response received from server');
//       } else {
//         // Something happened in setting up the request that triggered an Error
//         console.error('Error while setting up the request:', error.message);
//       }
//     }
//   };

//   return (
//     <div className="login-box ">
//       <div className="card">
//         <div className="card-body login-card-body">
//           <div className="login-logo pb-3">
//             {/* <a href="#"><img src="assets/img/logo-systools.png" width="170" height="60" alt="Logo" /></a> */}
//           </div>
//           <p className="h5 font-weight-bold text-center text-navy">Welcome To Team</p>
//           <p className="login-box-msg">Sign in to start your session</p>
//           <form onSubmit={handleSubmit}>
//             <div className="input-group mb-3">
//               <input
//                 type="email"
//                 className="form-control"
//                 placeholder="Email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//               />
//               <div className="input-group-append">
//                 <div className="input-group-text">
//                   <span className="fas fa-envelope"></span>
//                 </div>
//               </div>
//             </div>
//             <div className="input-group mb-3">
//               <input
//                 type="password"
//                 className="form-control"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 required
//               />
//               <div className="input-group-append">
//                 <div className="input-group-text">
//                   <span className="fas fa-lock"></span>
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-4">
//                 <button type="submit" className="btn btn-navy btn-block">Sign In</button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;



// login.js

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { useAuth } from '../context/auth.context.js';

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:5000/auth/login', { email, password });
//       console.log('Login response:', response.data);
//       login(response.data);
//       navigate('/');
//     } catch (error) {
//       console.error('Login error:', error);
//     }
//   };

//   return (
//     <div>
//       <h2>Login</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Email:</label>
//           <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
//         </div>
//         <div>
//           <label>Password:</label>
//           <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
//         </div>
//         <button type="submit">Login</button>
//       </form>
//     </div>
//   );
// };

// export default Login;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { useAuth } from '../context/auth.context.js';
// import './login.css'; // Import CSS file for styling

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://support.systoolsacademy.com/auth/login', { email, password });
//       console.log('Login response:', response.data);
//       login(response.data);
//       navigate('/');
//     } catch (error) {
//       console.error('Login error:', error);
//     }
//   };

//   return (
//     <div className='hold-transition login-page'>
//     <div className="login-box mx-auto">
//       <div className="card">
//         <div className="card-body login-card-body">
//           <div className="login-logo pb-3">
//             {/* <a href="#"><img src="assets/img/logo-systools.png" width="170" height="60" alt="Logo" /></a> */}
//           </div>
//           <p className="h5 font-weight-bold text-center text-navy">Welcome To Team</p>
//           <p className="login-box-msg">Sign in to start your session</p>
//           <form onSubmit={handleSubmit}>
//             <div className="input-group mb-3">
//               <input
//                 type="email"
//                 className="form-control"
//                 placeholder="Email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//               />
//               <div className="input-group-append">
//                 <div className="input-group-text">
//                   <span className="fas fa-envelope"></span>
//                 </div>
//               </div>
//             </div>
//             <div className="input-group mb-3">
//               <input
//                 type="password"
//                 className="form-control"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 required
//               />
//               <div className="input-group-append">
//                 <div className="input-group-text">
//                   <span className="fas fa-lock"></span>
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-4">
//                 <button type="submit" className="btn btn-navy btn-block">Sign In</button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default Login;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/auth.context.js';
import { Spinner } from 'react-bootstrap'; // Import Spinner component
import './login.css'; // Import CSS file for styling

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading spinner
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner when login request starts
    try {
      const response = await axios.post('http://support.systoolsacademy.com/auth/login', { email, password });
      console.log('Login response:', response.data);
      login(response.data);
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
    } finally {
      setLoading(false); // Hide spinner when login request completes
    }
  };

  return (
    <div className='hold-transition login-page'>
      <div className="login-box mx-auto">
        <div className="card">
          <div className="card-body login-card-body">
            <div className="login-logo pb-3">
              {/* <a href="#"><img src="assets/img/logo-systools.png" width="170" height="60" alt="Logo" /></a> */}
            </div>
            <p className="h5 font-weight-bold text-center text-navy">Welcome To Team</p>
            <p className="login-box-msg">Sign in to start your session</p>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button type="submit" className="btn btn-navy btn-block">
                    {loading ? <Spinner animation="border" size="sm" /> : 'Sign In'} {/* Show spinner or 'Sign In' */}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
