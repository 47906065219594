

// import React, { createContext, useState, useContext } from 'react';

// const AuthContext = createContext();

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };

// export const AuthProvider = ({ children }) => {
//     const [authData, setAuthData] = useState({ user: null, role: null });
  
//     const login = (userData) => {
//       const { token, role } = userData;
//       setAuthData({ user: token, role });
//     };
  
//     const logout = () => {
//       setAuthData({ user: null, role: null });
//     };
  
//     return (
//       <AuthContext.Provider value={{ authData, login, logout }}>
//         {children}
//       </AuthContext.Provider>
//     );
//   };
  



// import React, { createContext, useState, useContext } from 'react';

// const AuthContext = createContext();

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };

// export const AuthProvider = ({ children }) => {
//     const [authData, setAuthData] = useState({ user: null, role: null });
  
//     const login = (userData) => {
//       const { token, role } = userData;
//       console.log('Login successful. Token:', token, 'Role:', role);
//       setAuthData({ user: token, role });
//     };
  
//     const logout = () => {
//       console.log('Logout successful.');
//       setAuthData({ user: null, role: null });
//     };
  
//     return (
//       <AuthContext.Provider value={{ authData, login, logout }}>
//         {children}
//       </AuthContext.Provider>
//     );
//   };




// import React, { createContext, useState, useContext, useEffect } from 'react';

// const AuthContext = createContext();

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };

// export const AuthProvider = ({ children }) => {
//   const [authData, setAuthData] = useState(() => {
//     const storedAuthData = localStorage.getItem('authData');
//     console.log("user data :" ,storedAuthData)
//     return storedAuthData ? JSON.parse(storedAuthData) : { user: null, role: null , email: null };
//   });

//   const login = (userData) => {
//     const { token, role , email} = userData;
//     console.log('Login successful. Token:', token, 'Role:', role , 'email' , email);
//     const newAuthData = { user: token, role , email };
//     setAuthData(newAuthData);
//     localStorage.setItem('authData', JSON.stringify(newAuthData));
//   };

//   const logout = () => {
//     console.log('Logout successful.');
//     setAuthData({ user: null, role: null , email: null});
//     localStorage.removeItem('authData');
//   };

//   useEffect(() => {
//     const storedAuthData = localStorage.getItem('authData');
//     if (storedAuthData) {
//       setAuthData(JSON.parse(storedAuthData));
//     }
//   }, []);

//   return (
//     <AuthContext.Provider value={{ authData, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };



import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    const storedAuthData = localStorage.getItem('authData');
    return storedAuthData ? JSON.parse(storedAuthData) : { user: null, role: null , email: null };
  });

  const login = (userData) => {
    const { token, role , email} = userData;
    const newAuthData = { user: token, role , email };
    setAuthData(newAuthData);
    localStorage.setItem('authData', JSON.stringify(newAuthData));
  };

  const logout = () => {
    setAuthData({ user: null, role: null , email: null});
    localStorage.removeItem('authData');
  };

  const isAuthenticated = authData.user !== null;

  useEffect(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      setAuthData(JSON.parse(storedAuthData));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authData, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
