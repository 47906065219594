// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const CreateTicket = () => {
//     const priorities = ['Low', 'Medium', 'High'];
//     const statuses = ['Open', 'In Progress', 'Resolved'];
//     const types = ['Bug/Error', 'Feature Request', 'Security', 'Other'];

//     const [projects, setProjects] = useState([]);
//     const [users, setUsers] = useState([]);
//     const [title, setTitle] = useState('');
//     const [description, setDescription] = useState('');
//     const [projectName, setProjectName] = useState('');
//     const [assignee, setAssignee] = useState('');
//     const [priority, setPriority] = useState('Low');
//     const [status, setStatus] = useState('Open');
//     const [type, setType] = useState('Bug/Error');

//     useEffect(() => {
//         // get list of users
//         axios.get('http://localhost:5000/users/')
//             .then(res => {
//                 if(res.data.length > 0) {
//                     setUsers(res.data.map(user => user.name));
//                     setAssignee(res.data[0].name);
//                 }
//             })
//             .catch((error) => { console.log(error); });

//         // get list of projects
//         axios.get('http://localhost:5000/projects/')
//             .then(res => {
//                 if(res.data.length > 0) {
//                     setProjects(res.data.map(project => project.name));
//                     setProjectName(res.data[0].name);
//                 }
//             })
//             .catch((error) => { console.log(error); });
//     }, []);

//     const onSubmit = (e) => {
//         e.preventDefault();

//         const ticket = {
//             title: title,
//             description: description,
//             projectName: projectName,
//             assignee: assignee,
//             priority: priority,
//             status: status,
//             type: type
//         }

//         axios.post('http://localhost:5000/tickets/create', ticket)
//             .then(res => console.log(res.data));

//         alert('Successfully created.');

//         // clear form
//         setTitle('');
//         setDescription('');
//         setPriority('Low');
//         setStatus('Open');
//         setType('Bug/Error');
//     }

//     return (
//         <div>
//             <h3>Submit a Ticket</h3>
//             <form onSubmit={onSubmit}>
//                 <div className="form-group">
//                     <label>Title: </label>
//                     <input type="text"
//                            className="form-control"
//                            value={title}
//                            onChange={(e) => setTitle(e.target.value)}
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label>Description: </label>
//                     <textarea style={{resize: 'none'}}
//                               type="text"
//                               maxLength="250"
//                               rows="3"
//                               className="form-control"
//                               value={description}
//                               onChange={(e) => setDescription(e.target.value)}
//                     ></textarea>
//                 </div>
//                 <div className="form-group">
//                     <label>Product Name: </label>
//                     <select className="form-control"
//                             value={projectName}
//                             onChange={(e) => setProjectName(e.target.value)}>
//                         {
//                             projects.map((project) => {
//                                 return <option key={project} value={project}>{project}</option>;
//                             })
//                         }
//                     </select>
//                 </div>
//                 <div className="form-group">
//                     <label>Assigned To: </label>
//                     <select className="form-control"
//                             value={assignee}
//                             onChange={(e) => setAssignee(e.target.value)}>
//                         {
//                             users.map((user) => {
//                                 return <option key={user} value={user}>{user}</option>;
//                             })
//                         }
//                     </select>
//                 </div>
//                 <div className="form-group">
//                     <label>Priority: </label>
//                     <select className="form-control"
//                             value={priority}
//                             onChange={(e) => setPriority(e.target.value)}>
//                         {
//                             priorities.map((priority) => {
//                                 return <option key={priority} value={priority}>{priority}</option>;
//                             })
//                         }
//                     </select>
//                 </div>
//                 <div className="form-group">
//                     <label>Status: </label>
//                     <select className="form-control"
//                             value={status}
//                             onChange={(e) => setStatus(e.target.value)}>
//                         {
//                             statuses.map((status) => {
//                                 return <option key={status} value={status}>{status}</option>;
//                             })
//                         }
//                     </select>
//                 </div>
//                 <div className="form-group">
//                     <label>Type: </label>
//                     <select className="form-control"
//                             value={type}
//                             onChange={(e) => setType(e.target.value)}>
//                         {
//                             types.map((type) => {
//                                 return <option key={type} value={type}>{type}</option>;
//                             })
//                         }
//                     </select>
//                 </div>
//                 <div className="form-group">
//                     <input type="submit"
//                            value="Submit Ticket"
//                            className="btn btn-primary"
//                     />
//                 </div>
//             </form>
//         </div>
//     );
// }

// export default CreateTicket;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const CreateTicket = () => {
//     const priorities = ['Low', 'Medium', 'High'];
//     const statuses = ['Open', 'In Progress', 'Resolved'];
//     const types = ['Bug/Error', 'Feature Request', 'Security', 'Other'];

//     const [projects, setProjects] = useState([]);
//     const [users, setUsers] = useState([]);
//     const [title, setTitle] = useState('');
//     const [description, setDescription] = useState('');
//     const [projectName, setProjectName] = useState('');
//     const [assignee, setAssignee] = useState('');
//     const [priority, setPriority] = useState('Low');
//     const [status, setStatus] = useState('Open');
//     const [type, setType] = useState('Bug/Error');
//     const [attachment, setAttachment] = useState(null);

//     useEffect(() => {
//         // get list of users
//         axios.get('http://localhost:5000/users/')
//             .then(res => {
//                 if(res.data.length > 0) {
//                     setUsers(res.data.map(user => user.name));
//                     setAssignee(res.data[0].name);
//                 }
//             })
//             .catch((error) => { console.log(error); });

//         // get list of projects
//         axios.get('http://localhost:5000/projects/')
//             .then(res => {
//                 if(res.data.length > 0) {
//                     setProjects(res.data.map(project => project.name));
//                     setProjectName(res.data[0].name);
//                 }
//             })
//             .catch((error) => { console.log(error); });
//     }, []);

//     const onSubmit = (e) => {
//         e.preventDefault();

//         const formData = new FormData();
//         formData.append('title', title);
//         formData.append('description', description);
//         formData.append('projectName', projectName);
//         formData.append('assignee', assignee);
//         formData.append('priority', priority);
//         formData.append('status', status);
//         formData.append('type', type);
//         if (attachment) {
//             formData.append('attachment', attachment);
//         }

//         axios.post('http://localhost:5000/tickets/create', formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data'
//             }
//         })
//         .then(res => console.log(res.data))
//         .catch(error => console.log(error));

//         alert('Successfully created.');

//         // clear form
//         setTitle('');
//         setDescription('');
//         setPriority('Low');
//         setStatus('Open');
//         setType('Bug/Error');
//         setAttachment(null);
//     }

//     return (
//         <div>
//             <h3>Submit a Ticket</h3>
//             <form onSubmit={onSubmit}>
//                 <div className="form-group">
//                     <label>Title: </label>
//                     <input type="text"
//                            className="form-control"
//                            value={title}
//                            onChange={(e) => setTitle(e.target.value)}
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label>Description: </label>
//                     <textarea style={{resize: 'none'}}
//                               type="text"
//                               maxLength="250"
//                               rows="3"
//                               className="form-control"
//                               value={description}
//                               onChange={(e) => setDescription(e.target.value)}
//                     ></textarea>
//                 </div>
//                 <div className="form-group">
//                     <label>Product Name: </label>
//                     <select className="form-control"
//                             value={projectName}
//                             onChange={(e) => setProjectName(e.target.value)}>
//                         {
//                             projects.map((project) => {
//                                 return <option key={project} value={project}>{project}</option>;
//                             })
//                         }
//                     </select>
//                 </div>
//                 <div className="form-group">
//                     <label>Assigned To: </label>
//                     <select className="form-control"
//                             value={assignee}
//                             onChange={(e) => setAssignee(e.target.value)}>
//                         {
//                             users.map((user) => {
//                                 return <option key={user} value={user}>{user}</option>;
//                             })
//                         }
//                     </select>
//                 </div>
//                 <div className="form-group">
//                     <label>Priority: </label>
//                     <select className="form-control"
//                             value={priority}
//                             onChange={(e) => setPriority(e.target.value)}>
//                         {
//                             priorities.map((priority) => {
//                                 return <option key={priority} value={priority}>{priority}</option>;
//                             })
//                         }
//                     </select>
//                 </div>
//                 <div className="form-group">
//                     <label>Status: </label>
//                     <select className="form-control"
//                             value={status}
//                             onChange={(e) => setStatus(e.target.value)}>
//                         {
//                             statuses.map((status) => {
//                                 return <option key={status} value={status}>{status}</option>;
//                             })
//                         }
//                     </select>
//                 </div>
//                 <div className="form-group">
//                     <label>Type: </label>
//                     <select className="form-control"
//                             value={type}
//                             onChange={(e) => setType(e.target.value)}>
//                         {
//                             types.map((type) => {
//                                 return <option key={type} value={type}>{type}</option>;
//                             })
//                         }
//                     </select>
//                 </div>
//                 <div className="form-group">
//                     <label>Attachment: </label>
//                     <input type="file"
//                            className="form-control"
//                            onChange={(e) => setAttachment(e.target.files[0])}
//                     />
//                 </div>
//                 <div className="form-group">
//                     <input type="submit"
//                            value="Submit Ticket"
//                            className="btn btn-primary"
//                     />
//                 </div>
//             </form>
//         </div>
//     );
// }

// export default CreateTicket;




import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CreateTicket = () => {
    const priorities = ['Low', 'Medium', 'High'];
    const statuses = ['Open', 'In Progress', 'Resolved'];
    const types = ['Bug/Error', 'Feature Request', 'Security', 'Other'];

    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [projectName, setProjectName] = useState('');
    const [assignee, setAssignee] = useState('');
    const [assignedBy, setAssignedBy] = useState('');
    const [priority, setPriority] = useState('Low');
    const [status, setStatus] = useState('Open');
    const [type, setType] = useState('Bug/Error');
    const [attachment, setAttachment] = useState(null);

    useEffect(() => {
        // get list of users
        axios.get('http://support.systoolsacademy.com/users/')
        // axios.get('http://localhost:5000/users/')
            .then(res => {
                if (res.data.length > 0) {
                    setUsers(res.data.map(user => user.name));
                    setAssignee(res.data[0].name);
                    setAssignedBy(res.data[0].name); // default to first user
                }
            })
            .catch((error) => { console.log(error); });

        // get list of projects
        axios.get('http://support.systoolsacademy.com/projects/')
        // axios.get('http://localhost:5000/projects/')
            .then(res => {
                if (res.data.length > 0) {
                    setProjects(res.data.map(project => project.name));
                    setProjectName(res.data[0].name);
                }
            })
            .catch((error) => { console.log(error); });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('projectName', projectName);
        formData.append('assignee', assignee);
        formData.append('assignedBy', assignedBy);
        formData.append('priority', priority);
        formData.append('status', status);
        formData.append('type', type);
        if (attachment) {
            formData.append('attachment', attachment);
        }
    
        axios.post('http://support.systoolsacademy.com/tickets/create', formData, {
        // axios.post('http://localhost:5000/tickets/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => console.log(res.data))
        .catch(error => console.log(error));
    
        alert('Successfully created.');
    
        // clear form
        setTitle('');
        setDescription('');
        setPriority('Low');
        setStatus('Open');
        setType('Bug/Error');
        setAttachment(null);
    }
    

    return (
        <div>
            <h3>Submit a Ticket</h3>
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label>Title: </label>
                    <input type="text"
                           className="form-control"
                           value={title}
                           onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Description: </label>
                    <textarea style={{ resize: 'none' }}
                              type="text"
                              maxLength="250"
                              rows="3"
                              className="form-control"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Product Name: </label>
                    <select className="form-control"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}>
                        {
                            projects.map((project) => {
                                return <option key={project} value={project}>{project}</option>;
                            })
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>Assigned To: </label>
                    <select className="form-control"
                            value={assignee}
                            onChange={(e) => setAssignee(e.target.value)}>
                        {
                            users.map((user) => {
                                return <option key={user} value={user}>{user}</option>;
                            })
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>Assigned By: </label>
                    <select className="form-control"
                            value={assignedBy}
                            onChange={(e) => setAssignedBy(e.target.value)}>
                        {
                            users.map((user) => {
                                return <option key={user} value={user}>{user}</option>;
                            })
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>Priority: </label>
                    <select className="form-control"
                            value={priority}
                            onChange={(e) => setPriority(e.target.value)}>
                        {
                            priorities.map((priority) => {
                                return <option key={priority} value={priority}>{priority}</option>;
                            })
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>Status: </label>
                    <select className="form-control"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}>
                        {
                            statuses.map((status) => {
                                return <option key={status} value={status}>{status}</option>;
                            })
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>Type: </label>
                    <select className="form-control"
                            value={type}
                            onChange={(e) => setType(e.target.value)}>
                        {
                            types.map((type) => {
                                return <option key={type} value={type}>{type}</option>;
                            })
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>Attachment: </label>
                    <input type="file"
                           className="form-control"
                           onChange={(e) => setAttachment(e.target.files[0])}
                    />
                </div>
                <div className="form-group">
                    <input type="submit"
                           value="Submit Ticket"
                           className="btn btn-primary"
                    />
                </div>
            </form>
        </div>
    );
}

export default CreateTicket;
