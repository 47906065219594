import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CreateUser from './create-user';

const User = ({ user, deleteUser }) => (
  <tr>
    <td>{user.name}</td>
    <td>{user.email}</td>
    <td>{user.role}</td>
    <td>
      <a
        href="#"
        onClick={() => {
          if (window.confirm('Are you sure you want to delete this user?'))
            deleteUser(user._id);
        }}
        className="badge badge-danger"
      >
        Delete
      </a>
    </td>
  </tr>
);

const ManageUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get('http://support.systoolsacademy.com/users/')
      // .get('http://localhost:5000/users/')
      .then((res) => {
        setUsers(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteUser = (id) => {
    axios.delete('http://support.systoolsacademy.com/users/' + id).then((res) => {
    // axios.delete('http://localhost:5000/users/' + id).then((res) => {
      console.log(res.data);
    });

    // update users array to exclude the deleted user
    setUsers(users.filter((el) => el._id !== id));
  };

  const getUsers = () => {
    return users.map((currentUser) => {
      return (
        <User
          user={currentUser}
          deleteUser={deleteUser}
          key={currentUser._id}
        />
      );
    });
  };

  return (
    <div>
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{getUsers()}</tbody>
      </table>
      <br />
      <CreateUser />
    </div>
  );
};

export default ManageUsers;
