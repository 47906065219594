// // import React from 'react';
// // import { BrowserRouter as Router, Route  } from "react-router-dom";
// // import "bootstrap/dist/css/bootstrap.min.css";

// // // import components
// // import Navbar from "./components/navbar.component";
// // import Sidebar from "./components/sidebar.component";
// // import Dashboard from "./components/dashboard.component";
// // import CreateTicket from "./components/create-ticket.component";
// // import CreateUser from "./components/create-user.component";
// // import ManageUsers from "./components/manage-users.component";
// // import ManageProjects from "./components/manage-projects.component";
// // import EditTicket from "./components/edit-ticket.component";

// // export default function App() {
// //   return (
// //     <Router>
// //         <Navbar />
// //         <div className="wrapper">
// //             <Sidebar />
// //             <div id="content">
// //                 <Route path="/" exact component={Dashboard} />
// //                 <Route path="/tickets/create" component={CreateTicket} />
// //                 <Route path="/manage-users" component={ManageUsers} />
// //                 <Route path="/users/create" component={CreateUser} />
// //                 <Route path="/manage-projects" component={ManageProjects} />
// //                 <Route path="/edit/:id" component={EditTicket} />
// //             </div>
// //         </div>
// //     </Router>
// //   );
// // }



//   // import React from 'react';
//   // import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//   // import "bootstrap/dist/css/bootstrap.min.css";

//   // // Import components
//   // import Navbar from "./components/navbar";
//   // import Sidebar from "./components/sidebar";
//   // import Dashboard from "./components/dashboard";
//   // import CreateTicket from "./components/create-ticket";
//   // import CreateUser from "./components/create-user";
//   // import ManageUsers from "./components/manage-users";
//   // import ManageProjects from "./components/manage-projects";
//   // import EditTicket from "./components/edit-ticket";
//   // import Login from "./components/auth/login";
//   // import ProtectedRoute from "./components/protectedRoute";
//   // import { AuthProvider } from "./components/context/auth.context.js";

//   // export default function App() {
//   //   return (
//   //     <AuthProvider>
//   //       <Routes>

      
//   //       <Router>
//   //         <Navbar />
//   //         <div className="wrapper">
//   //           <Sidebar />
//   //           <div id="content">
          
//   //               <Route path="/login" component={Login} />
//   //               <ProtectedRoute path="/" exact component={Dashboard} />
//   //               <ProtectedRoute path="/tickets/create" component={CreateTicket} />
//   //               <ProtectedRoute path="/manage-users" component={ManageUsers} />
//   //               <ProtectedRoute path="/users/create" component={CreateUser} />
//   //               <ProtectedRoute path="/manage-projects" component={ManageProjects} />
//   //               <ProtectedRoute path="/edit/:id" component={EditTicket} />
            
//   //           </div>
//   //         </div>
//   //       </Router>
//   //       </Routes>
//   //     </AuthProvider>
//   //   );
//   // }


//   import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import "bootstrap/dist/css/bootstrap.min.css";

// // Import components
// import Navbar from "./components/navbar";
// import Sidebar from "./components/sidebar";
// import Dashboard from "./components/dashboard";
// import CreateTicket from "./components/create-ticket";
// import CreateUser from "./components/create-user";
// import ManageUsers from "./components/manage-users";
// import ManageProjects from "./components/manage-projects";
// import EditTicket from "./components/edit-ticket";
// import Login from "./components/auth/login";
// import ProtectedRoute from "./components/protectedRoute";
// import { AuthProvider } from "./components/context/auth.context.js";

// export default function App() {
//   return (
//     <AuthProvider>
//       <Router>
//         <Navbar />
//         <div className="wrapper">
//           <Sidebar />
//           <div id="content">
//             <Routes>
//               <Route path="/login" element={<Login />} />
//               <ProtectedRoute path="/" element={<Dashboard />} />
//               <ProtectedRoute path="/tickets/create" element={<CreateTicket />} />
//               <ProtectedRoute path="/manage-users" element={<ManageUsers />} />
//               <ProtectedRoute path="/users/create" element={<CreateUser />} />
//               <ProtectedRoute path="/manage-projects" element={<ManageProjects />} />
//               <ProtectedRoute path="/edit/:id" element={<EditTicket />} />
//             </Routes>
//           </div>
//         </div>
//       </Router>
//     </AuthProvider>
//   );
// }

// import React , {useState}from 'react';
// import { BrowserRouter as Router, Routes, Route , useNavigate   } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';
// import "bootstrap/dist/css/bootstrap.min.css";
// import Navbar from "./components/navbar.js";
// import Sidebar from "./components/sidebar.js";
// import Dashboard from "./components/dashboard.js";
// import CreateTicket from "./components/create-ticket.js";
// import CreateUser from "./components/create-user.js";
// import ManageUsers from "./components/manage-users.js";
// import ManageProjects from "./components/manage-projects.js";
// import EditTicket from "./components/edit-ticket.js";
// import Login from "./components/auth/login.js";
// // import ProtectedRoute from "./components/protectedRoute.js";
// import { AuthProvider } from "./components/context/auth.context.js";

// export default function App() {
//   // const Navigate = useNavigate();

//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const handleLogin = (token) => {
//     // Update the authentication status and store token in localStorage or sessionStorage
//     setIsAuthenticated(true);
//     // Store token in localStorage or sessionStorage
//   };
//   return (
//     <AuthProvider>
//       <Router>
//         <Navbar />
//         <div className="wrapper">
//           <Sidebar />
//           <div id="content">
//             <Routes>
//               {/* <Route path="/login" element={<Login />} /> */}
//               <Route path="/login" element={<Login onLogin={handleLogin} />} />
//               <Route path="/" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
//               <Route path="/" element={<Dashboard />} />
//               <Route path="/tickets/create" element={<CreateTicket />} />
//               <Route path="/manage-users" element={<ManageUsers />} />
//               <Route path="/users/create" element={<CreateUser />} />
//               <Route path="/manage-projects" element={<ManageProjects />} />
//               <Route path="/edit/:id" element={<EditTicket />} />
//             </Routes>
//           </div>
//         </div>
//       </Router>
//     </AuthProvider>
//   );
// }



// import React, { useState } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import "bootstrap/dist/css/bootstrap.min.css";
// import Navbar from "./components/navbar.js";
// import Sidebar from "./components/sidebar.js";
// import Dashboard from "./components/dashboard.js";
// import CreateTicket from "./components/create-ticket.js";
// import CreateUser from "./components/create-user.js";
// import ManageUsers from "./components/manage-users.js";
// import ManageProjects from "./components/manage-projects.js";
// import EditTicket from "./components/edit-ticket.js";
// import Login from "./components/auth/login.js";
// import { AuthProvider } from "./components/context/auth.context.js";

// export default function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const handleLogin = (token) => {
//     // Update the authentication status and store token in localStorage or sessionStorage
//     setIsAuthenticated(true);
//     // Store token in localStorage or sessionStorage
//   };

//   return (
//     <AuthProvider>
//       <Router>
//         <Navbar />
//         <div className="wrapper">
//           <Sidebar />
//           <div id="content">
//             <Routes>
//               {/* Redirect to Login page if not authenticated */}
//               <Route path="/" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
//               <Route path="/login" element={<Login onLogin={handleLogin} />} />
//               {/* Define other routes here */}
//               <Route path="/tickets/create" element={<CreateTicket />} />
//               <Route path="/manage-users" element={<ManageUsers />} />
//               <Route path="/users/create" element={<CreateUser />} />
//               <Route path="/manage-projects" element={<ManageProjects />} />
//               <Route path="/edit/:id" element={<EditTicket />} />
//             </Routes>
//           </div>
//         </div>
//       </Router>
//     </AuthProvider>
//   );
// }



// import React, { useState } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import "bootstrap/dist/css/bootstrap.min.css";
// import Navbar from "./components/navbar.js";
// import Sidebar from "./components/sidebar.js";
// import Dashboard from "./components/dashboard.js";
// import CreateTicket from "./components/create-ticket.js";
// import CreateUser from "./components/create-user.js";
// import ManageUsers from "./components/manage-users.js";
// import ManageProjects from "./components/manage-projects.js";
// import EditTicket from "./components/edit-ticket.js";
// import Login from "./components/auth/login.js";
// import { AuthProvider } from "./components/context/auth.context.js";

// export default function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const handleLogin = (token) => {
//     // Update the authentication status and store token in localStorage or sessionStorage
//     setIsAuthenticated(true);
//     // Store token in localStorage or sessionStorage
//   };

//   return (
//     <AuthProvider>
//       <Router>
//         <Navbar />
//         <div className="wrapper">
//           <Sidebar isAuthenticated={isAuthenticated} />
//           <div id="content">
//             <Routes>
//               {/* Redirect to Login page if not authenticated */}
//               <Route path="/" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
//               <Route path="/login" element={<Login onLogin={handleLogin} />} />
//               {/* Define other routes here */}
//               <Route path="/tickets/create" element={<CreateTicket />} />
//               <Route path="/manage-users" element={<ManageUsers />} />
//               <Route path="/users/create" element={<CreateUser />} />
//               <Route path="/manage-projects" element={<ManageProjects />} />
//               <Route path="/edit/:id" element={<EditTicket />} />
//             </Routes>
//           </div>
//         </div>
//       </Router>
//     </AuthProvider>
//   );
// }




import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/navbar.js";
import Sidebar from "./components/sidebar.js";
import Dashboard from "./components/dashboard.js";
import CreateTicket from "./components/create-ticket.js";
import CreateUser from "./components/create-user.js";
import ManageUsers from "./components/manage-users.js";
import ManageProjects from "./components/manage-projects.js";
import EditTicket from "./components/edit-ticket.js";
import Login from "./components/auth/login.js";
import { AuthProvider, useAuth } from "./components/context/auth.context.js";

export default function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

function AppContent() {
  const { authData, login } = useAuth();

  const handleLogin = (userData) => {
    login(userData); // Update authentication context with user data including role
  };

  // Extract user role from authData
  const userRole = authData ? authData.role : '';

  return (
    <Router>
      <Navbar />
      <div className="wrapper">
        <Sidebar isAuthenticated={authData.user !== null} userRole={userRole} />
        <div id="content">
          <Routes>
            {/* Redirect to Login page if not authenticated */}
            <Route path="/" element={authData.user !== null ? <Dashboard /> : <Navigate to="/login" />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />

            {/* Define other routes here */}
            <Route path="/tickets/create" element={<CreateTicket />} />
            <Route path="/manage-users" element={<ManageUsers />} />
            <Route path="/users/create" element={<CreateUser />} />
            <Route path="/manage-projects" element={<ManageProjects />} />
            <Route path="/edit/:id" element={<EditTicket />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

