// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Ticket from './ticket-display';

// const TicketList = () => {
//     const [tickets, setTickets] = useState([]);

//     useEffect(() => {
//         axios.get('http://localhost:5000/tickets/')
//             .then(res => {
//                 setTickets(res.data);
//             })
//             .catch(error => console.log(error));
//     }, []);

//     const deleteTicket = (id) => {
//         axios.delete('http://localhost:5000/tickets/' + id)
//             .then(res => { console.log(res.data) });

//         // Update tickets array to remove the ticket with the matching id
//         setTickets(tickets.filter(ticket => ticket._id !== id));
//     }

//     const getOpenList = () => {
//         return tickets.map(currentTicket => {
//             if (currentTicket.status !== 'Resolved')
//                 return <Ticket
//                     ticket={currentTicket}
//                     deleteTicket={deleteTicket}
//                     key={currentTicket._id}
//                 />;
//         })
//     }

//     const getResolvedList = () => {
//         return tickets.map(currentTicket => {
//             if (currentTicket.status === 'Resolved')
//                 return <Ticket
//                     ticket={currentTicket}
//                     deleteTicket={deleteTicket}
//                     key={currentTicket._id}
//                 />;
//         })
//     }

//     return (
//         <div>
//             <br></br>
//             <h3>Open Tickets</h3>
//             <table className="table">
//                 <thead className="thead-light">
//                     <tr>
//                         <th>Title</th>
//                         <th>Description</th>
//                         <th>Project</th>
//                         <th>Assigned To</th>
//                         <th>Assigned By</th>
//                         <th>Priority</th>
//                         <th>Status</th>
//                         <th>Type</th>
//                         <th>Actions</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {getOpenList()}
//                 </tbody>
//             </table>
//             <br></br>
//             <h3>Resolved Tickets</h3>
//             <table className="table">
//                 <thead className="thead-light">
//                     <tr>
//                         <th>Title</th>
//                         <th>Description</th>
//                         <th>Project</th>
//                         <th>Assigned To</th>
//                         <th>Assigned By</th>
//                         <th>Priority</th>
//                         <th>Status</th>
//                         <th>Type</th>
//                         <th>Actions</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {getResolvedList()}
//                 </tbody>
//             </table>
//         </div>
//     );
// }

// export default TicketList;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Ticket from './ticket-display';

// const TicketList = () => {
//     const [tickets, setTickets] = useState([]);

//     useEffect(() => {
//         // Fetching tickets from the server
//         axios.get('http://localhost:5000/tickets/')
//             .then(res => {
//                 setTickets(res.data);
//             })
//             .catch(error => console.log(error));
//     }, []);

//     // Function to delete a ticket
//     const deleteTicket = (id) => {
//         axios.delete(`http://localhost:5000/tickets/${id}`)
//             .then(res => {
//                 console.log(res.data);
//                 // Update tickets array to remove the ticket with the matching id
//                 setTickets(tickets.filter(ticket => ticket._id !== id));
//             })
//             .catch(error => console.log(error));
//     }

//     // Function to filter open tickets
//     const getOpenList = () => {
//         return tickets.map(currentTicket => {
//             if (currentTicket.status !== 'Resolved')
//                 return <Ticket
//                     ticket={currentTicket}
//                     deleteTicket={deleteTicket}
//                     key={currentTicket._id}
//                 />;
//             return null;
//         });
//     }

//     // Function to filter resolved tickets
//     const getResolvedList = () => {
//         return tickets.map(currentTicket => {
//             if (currentTicket.status === 'Resolved')
//                 return <Ticket
//                     ticket={currentTicket}
//                     deleteTicket={deleteTicket}
//                     key={currentTicket._id}
//                 />;
//             return null;
//         });
//     }

//     return (
//         <div>
//             <br />
//             <h3>Open Tickets</h3>
//             <table className="table">
//                 <thead className="thead-light">
//                     <tr>
//                         <th>Title</th>
//                         <th>Description</th>
//                         <th>Project</th>
//                         <th>Assigned To</th>
//                         <th>Assigned By</th>
//                         <th>Priority</th>
//                         <th>Status</th>
//                         <th>Type</th>
//                         <th>Actions</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {getOpenList()}
//                 </tbody>
//             </table>
//             <br />
//             <h3>Resolved Tickets</h3>
//             <table className="table">
//                 <thead className="thead-light">
//                     <tr>
//                         <th>Title</th>
//                         <th>Description</th>
//                         <th>Project</th>
//                         <th>Assigned To</th>
//                         <th>Assigned By</th>
//                         <th>Priority</th>
//                         <th>Status</th>
//                         <th>Type</th>
//                         <th>Actions</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {getResolvedList()}
//                 </tbody>
//             </table>
//         </div>
//     );
// }

// export default TicketList;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Ticket from './ticket-display';

const TicketList = () => {
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        // Fetching tickets from the server
        axios.get('http://support.systoolsacademy.com/tickets/')
        // axios.get('http://localhost:5000/tickets/')
            .then(res => {
                setTickets(res.data);
            })
            .catch(error => console.log(error));
    }, []);

    // Function to delete a ticket
    const deleteTicket = (id) => {
        axios.delete(`http://support.systoolsacademy.com/tickets/${id}`)
        // axios.delete(`http://localhost:5000/tickets/${id}`)
            .then(res => {
                console.log(res.data);
                // Update tickets array to remove the ticket with the matching id
                setTickets(tickets.filter(ticket => ticket._id !== id));
            })
            .catch(error => console.log(error));
    }

    // Function to update the ticket status
    const updateTicketStatus = (id, status) => {
        axios.put(`http://support.systoolsacademy.com/tickets/${id}`, { status })
        // axios.put(`http://localhost:5000/tickets/${id}`, { status })
            .then(res => {
                setTickets(tickets.map(ticket => (ticket._id === id ? { ...ticket, status } : ticket)));
            })
            .catch(error => console.log(error));
    }

    // Function to filter open tickets
    const getOpenList = () => {
        return tickets.map(currentTicket => {
            if (currentTicket.status !== 'Resolved')
                return <Ticket
                    ticket={currentTicket}
                    deleteTicket={deleteTicket}
                    updateTicketStatus={updateTicketStatus}
                    key={currentTicket._id}
                />;
            return null;
        });
    }

    // Function to filter resolved tickets
    const getResolvedList = () => {
        return tickets.map(currentTicket => {
            if (currentTicket.status === 'Resolved')
                return <Ticket
                    ticket={currentTicket}
                    deleteTicket={deleteTicket}
                    updateTicketStatus={updateTicketStatus}
                    key={currentTicket._id}
                />;
            return null;
        });
    }

    return (
        <div>
            <br />
            <h3>Open Tickets</h3>
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Project</th>
                        <th>Assigned To</th>
                        <th>Assigned By</th>
                        <th>Priority</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {getOpenList()}
                </tbody>
            </table>
            <br />
            <h3>Resolved Tickets</h3>
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Project</th>
                        <th>Assigned To</th>
                        <th>Assigned By</th>
                        <th>Priority</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {getResolvedList()}
                </tbody>
            </table>
        </div>
    );
}

export default TicketList;
