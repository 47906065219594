import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';

const PriorityChart = () => {
    const [tickets, setTickets] = useState([]);
    const [data, setData] = useState({
        labels: ['Low', 'Medium', 'High'],
        datasets: [{
            data: [0, 0, 0],
            barPercentage: '0.5',
            backgroundColor: ['lightgreen', 'moccasin', 'crimson']
        }]
    });

    useEffect(() => {
        let low = 0;
        let medium = 0;
        let high = 0;

        axios.get('http://localhost:5000/tickets/')
            .then(res => {
                setTickets(res.data);
                res.data.forEach(ticket => {
                    if (ticket.status !== 'Resolved') {
                        switch (ticket.priority) {
                            case 'Low':
                                low++;
                                break;
                            case 'Medium':
                                medium++;
                                break;
                            case 'High':
                                high++;
                                break;
                            default:
                                break;
                        }
                    }
                });
                setData({
                    ...data,
                    datasets: [{
                        data: [low, medium, high]
                    }]
                });
            })
            .catch(error => console.log(error));
    }, []);

    return (
        <div>
            <Bar 
                data={data}
                options={{
                    legend: { display: false },
                    maintainAspectRatio: false,
                    responsive: false,
                    scales: {
                        yAxes: [{
                            ticks: { beginAtZero: true }
                        }]
                    }
                }}
                height={350}
                width={500} 
            />
        </div>
    );
}

export default PriorityChart;
